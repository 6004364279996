import React from "react"
import { Button, Container } from "@mui/material"
import { Box } from "@mui/system"
import DefaultHeroImg from "../../images/home-bg.png"
import { Link } from "gatsby"
import CheckAvailability from "./check-availability.tsx"
import FmdGoodIcon from "@mui/icons-material/FmdGood"
import { DateRange } from "@mui/lab/DateRangePicker"
//import locationIconss from "../../images/location.svg"

type Props = {
  heading: string
  description?: string
  imgUrl: string
  isHome?: boolean
  ShowAvailability?: boolean
  showBookingOptons?: boolean
  aboutBannerBg?: boolean
  linkTitle: string
  link: string
  linkIcon: string
}
export const HeroSection: React.FC<Props> = ({
  heading,
  description,
  linkTitle,
  link,
  linkIcon,
  imgUrl,
  isHome,
  showBookingOptons,
  ShowAvailability,
  aboutBannerBg,
}) => {
  // const [value, setValue] = React.useState<DateRange<Date>>([null, null])
  // const [guests, setGuests] = React.useState(1)
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        background: `url(${imgUrl || DefaultHeroImg})`,
        backgroundSize: "cover",
        backgroundPosition: aboutBannerBg ? "bottom" : "center",
        display: "flex",
        alignItems: isHome ? "center" : "flex-end",
        padding: "84px 0",
        textAlign: isHome ? "center" : "left",
        color: "#FFFFFF",
        backgroundColor: "rgba(0, 0, 0, 0.22)",
        backgroundBlendMode: "color",
        backgroundRepeat: "no-repeat",
        borderBottom: isHome ? "2px solid #FFFFFF" : null,
        "@media screen and (max-width: 767px)": {
          alignItems: isHome ? "flex-end" : "flex-end",
          textAlign: isHome ? "left" : "left",
          backgroundPosition: isHome
            ? "left"
            : aboutBannerBg
            ? "bottom"
            : "center",
        },
        "@media screen and (max-width: 767px) and (orientation: landscape)": {
          paddingBottom: "0",
        },
        h2: {
          position: "relative",
          zIndex: 2,
        },
        "h1, h2": {
          color: "inherit",
          "@media screen and (max-width: 767px)": {
            order: ShowAvailability ? 2 : null,
            width: "100%",
            mt: ShowAvailability ? "17px" : null,
            lineHeight: ShowAvailability ? "26px" : "35px",
          },
          "@media screen and (max-width: 891px) and (orientation: landscape)": {
            width: "100%",
          },
        },
        "& p": {
          maxWidth: "650px",
          order: ShowAvailability ? 2 : null,
          "@media screen and (max-width: 767px)": {
            maxWidth: "275px",
          },
        },
        ".bannerlink": {
          background: "rgba(255, 255, 255, 0.2)",
          borderRadius: "5px",
          padding: "10px",
          color: "#FFFFFF",
          textDecoration: "none",
          fontSize: "14px",
          lineHeight: "20px",
          outline: "none",
          border: "none",
          transition: "all ease-in-out 250ms",
          position: "relative",
          zIndex: 2,
        },
        "button:hover": {
          background: "rgba(255, 255, 255, 0.2)",
        },
        ".bannerlink img": {
          marginRight: "12px",
        },
      }}
    >
      <Container
        maxWidth={isHome ? "md" : "lg"}
        sx={{
          minHeight: isHome ? "" : "250px",
          "@media screen and (max-width: 767px)": {
            display: ShowAvailability ? "flex" : null,
            flexWrap: ShowAvailability ? "wrap" : null,
            minHeight: isHome ? "" : "0",
            maxWidth: isHome ? "80% !important" : "100% !important",
            ml: "unset",
          },
          "@media screen and (max-width: 891px) and (orientation: landscape)": {
            display: ShowAvailability ? "flex" : "flex",
            flexWrap: ShowAvailability ? "wrap" : "wrap",
            minHeight: isHome ? "" : "0",
            justifyContent: ShowAvailability ? "center" : null,
          },
          "@media screen and (max-width: 767px) and (orientation: landscape)": {
            justifyContent: ShowAvailability ? "flex-start" : null,
          },
        }}
      >
        {isHome ? <h2>{heading}</h2> : <h1>{heading}</h1>}
        {linkTitle && (
          <button className="bannerlink">
            {" "}
            <FmdGoodIcon sx={{ color: "white", marginRight: "12px" }} />
            {/* <img src={linkIcon} alt="location Icon" /> */}
            {linkTitle}
          </button>
        )}
        {description && <p>{description}</p>}
        {ShowAvailability ? <CheckAvailability /> : null}
        {/* //value={value} setValue={setValue} guests={guests} setGuests={setGuests}/> : null} */}
      </Container>
    </Box>
  )
}
