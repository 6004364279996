import * as React from "react"
import * as styles from "./styles.module.css"
import TextField from "@mui/material/TextField"
//import {DateRangePicker, DateRange } from "@mui"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import  DateRangePicker   from '@mui/x-date-pickers';
// import { DateRange} from '@mui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker"

import Box from "@mui/material/Box"
import { Button } from "@mui/material"
import { navigate } from "gatsby"

// type props = {
//   value: DateRange<Date>
//   setValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>
//   guests: Number
//   setGuests: React.Dispatch<React.SetStateAction<number>>
// }

const checkAvailability: React.FC = () => {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null])
  const [guests, setGuests] = React.useState(1)

  const [disablepopup, setDisablepopup] = React.useState(false)
  return (
    <div className={styles.bookingSection}>
      <div className={styles.mobileBookingSection}>
        <Button
          className={styles.BookNowButtonMobile}
          color="primary"
          variant="contained"
          onClick={() => navigate("/contact/")}
        >
          Buchen
        </Button>
      </div>
      <div className={styles.CheckInCheckOut}>
        <Box
          sx={{
            label: {
              background: "url(/images/grommet-icons_form-next.svg)",
              opacity: 1,
              width: "100%",
              maxWidth: "100%",
              backgroundSize: "17px 26px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "85px 0",
              left: "-13px",
              textAlign: "left",
            },
            ".Mui-focused": {
              color: "#fff !important",
              opacity: 0.5,
            },
            ".MuiFormControl-root:first-child label": {
              backgroundPosition: "70px 0",
            },
            ".sixhouse-1xhj18k": {},
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Check-In"
              endText="Check-Out"
              disablePast={true}
              calendars={2}
              value={value}
              onChange={newValue => {
                //console.log(newValue)
                setValue(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 1 }}></Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
      </div>
      <div className={styles.guestsnumber}>
        <div className={styles.quantity}>
          <p>Gäste</p>
          <input
            type="button"
            value="-"
            className={styles.minus}
            onClick={() => {
              if (guests >= 2) {
                setGuests(guests - 1)
              }
            }}
          />
          <input
            type="text"
            name="quantity"
            value={guests}
            className={styles.qty}
          />
          <input
            type="button"
            value="+"
            className={styles.plus}
            onClick={() => {
              if (guests <= 9) {
                setGuests(guests + 1)
              }
            }}
          />
        </div>
      </div>
      <div className={styles.checkAvailabilityButtonSec}>
        <Button
          className={styles.checkAvailabilityButton}
          color="primary"
          variant="contained"
          onClick={() => {
            setGuests(guests)
            setValue(value)
            navigate("/contact/", {
              state: { dataValue: { value }, guest: { guests } },
            })
          }}
        >
          Verfügbarkeit prüfen!
        </Button>
      </div>
    </div>
  )
}

export default checkAvailability
